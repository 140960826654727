export const qiyeData = [
  { title: '高新技术企业', img: require("../assets1/1.jpg") },
  { title: '守合同重信用企业', img: require("../assets1/2.jpg") },
  { title: 'AAA级诚信企业', img: require("../assets1/3.jpg") },
  { title: 'AAA级诚信企业', img: require("../assets1/4.jpg") },
  { title: '加中贸易理事会会员', img: require("../assets1/5.jpg") },
  { title: '新加坡品牌', img: require("../assets1/6.jpg") },
  { title: '中国反侵权假冒副理事长单位', img: require("../assets1/7.jpg") },
  { title: '中国质量协会会员单位', img: require("../assets1/8.jpg") },
  { title: '双打工作促进奖', img: require("../assets1/9.jpg") },
  { title: '十大维权打假先进单位', img: require("../assets1/10.jpg") },
  { title: '经济发展明星企业', img: require("../assets1/11.jpg") },
  { title: '经济发展功勋企业', img: require("../assets1/12.jpg") },
  { title: '优秀企业', img: require("../assets1/13.jpg") },
  { title: '山东省制造业单项冠军企业', img: require("../assets1/14.jpg") },
  { title: '安全生产工作先进单位', img: require("../assets1/15.jpg") },
  { title: '中国国际商会战略黄金合作伙伴', img: require("../assets1/16.jpg") },
  { title: '2018上合组织工商论坛合作伙伴', img: require("../assets1/17.jpg") },
  { title: '中国国际商会理事单位', img: require("../assets1/18.jpg") },
  { title: '优秀民营企业', img: require("../assets1/19.jpg") },
  { title: '安全生产标准化三级企业', img: require("../assets1/20.jpg") },
  { title: '山东省质量管理优秀企业', img: require("../assets1/21.jpg") },
  { title: '烟台市中小企业创新转型优胜企业', img: require("../assets1/22.jpg") },
  { title: 'AAA级用户满意标杆级企业', img: require("../assets1/23.jpg") },
  { title: '企业社会责任先锋奖', img: require("../assets1/24.jpg") },
  { title: '烟台市最具社会责任企业', img: require("../assets1/25.jpg") },
  { title: '国民信赖十大健康品牌', img: require("../assets1/26.jpg") },
  { title: '山东省品牌创新成果奖', img: require("../assets1/27.jpg") },
  { title: '山东省品牌创新成果奖', img: require("../assets1/28.jpg") },
  { title: '高新技术企业', img: require("../assets1/29.jpg") },
  { title: '省级用户满意五星企业', img: require("../assets1/30.jpg") },
  { title: '市场质量AAA级信用企业', img: require("../assets1/31.jpg") },
  { title: '山东省知名商标', img: require("../assets1/32.jpg") },

]

export const chanpinData = [
  { title: '新疆优秀新产品二等奖', img: require("../assets1/101.jpg") },
  { title: '国家级新产品', img: require("../assets1/102.jpg") },
  { title: '世界针灸及自然医学金奖', img: require("../assets1/103.jpg") },
  { title: '全国发明与专利博览会金杯奖', img: require("../assets1/104.jpg") },
  { title: '上海中外产品博览会优秀产品奖', img: require("../assets1/105.jpg") },
  { title: '第九届全国发明展览会银奖', img: require("../assets1/106.jpg") },
  { title: '中国国际技术产品展览会金奖', img: require("../assets1/107.jpg") },
  { title: '中国民营科技企业科技博览会金奖', img: require("../assets1/108.jpg") },
  { title: '亚太健康超级品牌', img: require("../assets1/109.jpg") },
  { title: '亚太区最具品牌价值明显产品', img: require("../assets1/110.jpg") },
  { title: '山东省用户满意产品', img: require("../assets1/111.jpg") },
  { title: '山东省企业品牌创新成果一等奖', img: require("../assets1/112.jpg") },
  { title: '山东省企业技术创新一等奖', img: require("../assets1/113.jpg") },
  { title: '全国市场质量信用满意产品', img: require("../assets1/114.jpg") },
  { title: '山东省企业技术创新二等奖', img: require("../assets1/115.jpg") },
  { title: '国家级四星级用户满意产品', img: require("../assets1/116.jpg") },
  // { title: '2023年国家级四星级用户满意产品', img: require("../assets1/2023年国家级四星级用户满意产品.jpg") },
]
<template>
  <div class="container">
    <Header />
    <div class="into-banner">
      <img src="../../assets/into_banner.jpg" />
    </div>
    <van-tabs
      v-model="active"
      line-width="4rem"
      animated
      color="#de006f"
      @click="onClick"
    >
      <van-tab title="集团简介" class="jianjie">
        <div class="neir">
          <!-- <p style="text-indent: 1.9rem">
            金天国际领创全球生殖养护产业
          </p> -->
          <p style="text-indent: 1.9rem"><b>  金天国际领创全球生殖养护产业</b></p>
          <p style="text-indent: 1.9rem">
            金天国际始创于1991年，是专业从事生殖养护技术之雪莲养护贴研发、生产和推广的国际化高科技企业。金天国际以“让生命充满活力”为核心，致力于让人们拥有更加健康、积极、满意的生活品质方式，帮助人们充满活力地生活。 历经三十余年发展，现已形成“生殖养护、美好商城、云健康、飞行营地”暨一核三星四大产业发展格局。拥有支撑四大产业发展的科研、制造、营销、服务等全资或控股子公司30余家，市场辐射全球70多个国家和地区，累计用户超1亿人次。
          </p>
          <p style="text-indent: 1.9rem"><b>金天国际一核三星产业布局</b></p>
          <p style="text-indent: 1.9rem"><b>生殖养护产业：</b></p>
          <p style="text-indent: 1.9rem">
            2021年10月8日，由金天国际核心企业烟台金蕊女性用品有限公司、江苏金天雪莲生态保养有限公司及中国科学院青岛生物能源与过程研究所等发起并起草，经中国轻工业联合会提出并归口的《雪莲养护贴》行业标准(QB/T5630-2021)，由中华人民共和国工业和信息化部正式向全球首次发布并于2022年2月1日正式实施。
           </p>
           <p style="text-indent: 1.9rem">
            在《雪莲养护贴》标准“范围”内明确规定:雪莲养护贴是“以纯棉织物作为亲肤面层，将雪莲等多种草本植物制成的精华素置于中间功能层，再覆以透气膜经专用机械加工成型，用于人体生殖部位养护的一次性用品。”至此，雪莲养护贴是中国以行标的法定形式率先在全球发布的生殖养护技术和产品，由此开启了一个全新的生殖养护产业。
          </p>
           <p style="text-indent: 1.9rem">
            雪莲养护贴行业标准的颁布和实施标志着雪莲养护贴成为了满足人们生殖养护需求的新技术、新产品和新方式。一种继刷牙、护肤之后，服务于全人类生殖养护的第三大生活品质方式正式开启，一个全新的生殖养护产业正式诞生，今年流行贴R正在席卷全球！
          </p>
          <p style="text-indent: 1.9rem"><b>美好商城：</b></p>

          <p style="text-indent: 1.9rem">
            美好商城是在中国国际经济交流中心、中国贸促会、中国国际商会、中国流通协会、中国健康促进基金会等国内权威机构的支持和指导下，由金天国际发起并承建的全球首个新消费交互式企业联盟商城。
             </p>
          <p style="text-indent: 1.9rem"><b>飞行营地产业：</b></p>
          <p style="text-indent: 1.9rem">
            金天国际飞行营地是金天国际集团与蓝天航空集团通过优势互补、强强联合组建的一家以创新发展中国通用航空事业为己任的平台式通用航空经营实体，是经中国民用航空局批准成立的涵盖甲、乙、丙三类经营范围的通用航空公司。
          </p>
        
          <p style="text-indent: 1.9rem"><b>云健康产业：</b></p>
          <p style="text-indent: 1.9rem">
            专注于5S全生命质量管理、细胞技术的研发与应用，与中国健康促进基金会、中国医学科学院&北京协和医学院等权威机构实现产学研共建，打造国内一流的生命质量管理产业基地。
          </p>
          <p style="text-indent: 1.9rem"><b>金天国际第一个30年发展规划：实现生殖养护产业化，让生命充满活力</b></p>
          <p style="text-indent: 1.9rem"><b>产品：</b></p>
          <p style="text-indent: 1.9rem">
            金天国际创造生殖养护品---雪莲养护贴，历经 30 年完成 5 次产品更迭
           </p>
           <p style="text-indent: 1.9rem">
            1993 年开创 首创雪莲贴垫剂型--妇得乐雪莲药垫（治疗功能）
           </p>
           <p style="text-indent: 1.9rem">
            1996 年升级 二代雪莲贴垫剂型--复方雪莲药垫（预防保健功能）
           </p>
           <p style="text-indent: 1.9rem">
            2004 年迭代 三代雪莲贴垫剂型—雪莲妇贴（护理功能）
           </p>
           <p style="text-indent: 1.9rem">
            2006 年醇熟 四代雪莲贴垫剂型—雪莲生态保养贴（保养功能）
           </p>
           <p style="text-indent: 1.9rem">
            2022 年产业化 五代雪莲贴垫剂型—雪莲养护贴（行业标准，养护功能）
           </p>
           <p style="text-indent: 1.9rem"><b>技术：</b></p>
          <p style="text-indent: 1.9rem">
            拥有天山雪莲组培技术、产品功效成分数字化组方技术、指纹图谱质控分析技术（三大核心技术）及 50 余项专利技术。
           </p>
           <p style="text-indent: 1.9rem"><b>模式：</b></p>
          <p style="text-indent: 1.9rem">
            独创三维动态特许专营模式。
           </p>
           <p style="text-indent: 1.9rem"><b>产业：</b></p>
          <p style="text-indent: 1.9rem">
            雪莲养护贴行业标准的颁布和实施，标志着生殖养护产业正式诞生，一种继刷牙、护肤之后， 服务于全人类生殖养护的第三种生活品质方式正式开启。
          </p>
          <p style="text-indent: 1.9rem">
            至此，金天国际第一个 30 年规划【产品、技术、模式、产业】完美收官。
          </p>
            <p style="text-indent: 1.9rem"><b>金天国际第二个30年发展规划：普及雪莲养护贴产业，让更多生命充满活力</b></p>
            <p style="text-indent: 1.9rem"><b>产业拓展期：</b></p>
          <p style="text-indent: 1.9rem">
            2023 年-2033 年完成全球市场的布局，2025 年前贴 R 用户数量达到 500 万以上，年市场销售额 500 亿以上；2027 年前贴 R用户数量达到 1000 万以上，年市场销售额 1000 亿以上；2033 年前贴 R 用户数量达到 1 亿以上，年市场销售额万亿以上；
           </p>
            <p style="text-indent: 1.9rem"><b>产业巩固期：</b></p>
          <p style="text-indent: 1.9rem">
            2033 年-2043 年持续普及生殖养护之雪莲养护的生活品质方式，让全球至少 10 亿人养成如刷牙护肤一样的生殖养护习惯，全球实现十万亿级产业市场规模；
           </p>
            <p style="text-indent: 1.9rem"><b>产业提升期：</b></p>
          <p style="text-indent: 1.9rem">
            2043 年-2053 年在持续科研的基础上，根据市场需求变化推出更多适应生殖养护的新技术、新标准、新方式，打造全球最有价值的企业，成就百年长盛的伟大企业。
           </p>
            <!-- <p style="text-indent: 1.9rem"><b>产业巩固期2044-2053年：</b></p>
          <p style="text-indent: 1.9rem">
            在持续科研的基础上，根据市场需求变化推出更多适应生殖养护的新技术、新标准、新方式，打造全球最有价值的企业，成就百年长盛的伟大企业。
            </p> -->
        </div>
      </van-tab>
      <van-tab title="集团要闻" class="yaowen" @click="getNews()">
        <div class="item" v-for="(item, index) in newsData" :key="index">
          <router-link :to="'/newsdetail/' + item.newsID">
            <div class="item-img">
              <img :src="imgUrl + item.yImgPath" />
            </div>
            <div class="item-title">
              {{ item.title }}
            </div>
            <div class="item-detail">
              <div class="detail-date">
                {{
                  dateFormat("YYYY-mm-dd", item.ReleaseTime.substring(6, 19))
                }}
              </div>
              <div class="detail-more">查看详情&nbsp;→</div>
            </div>
          </router-link>
        </div>
      </van-tab>
      <van-tab title="集团荣誉" class="rongyu">
        <div class="label">— 企业荣誉 —</div>
        <div class="wrapper">
          <div class="item" v-for="(item, index) in qiyeData" :key="index">
            <div class="item-content">
              <div v-viewer>
                <img :src="item.img" v-lazy="item.img"/>
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="label">— 产品荣誉 —</div>
        <div class="wrapper">
          <div class="item" v-for="(item, index) in chanpinData" :key="index">
            <div class="item-content">
              <div v-viewer>
                <img :src="item.img" loading="lazy"/>
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="集团文化" class="wenhua">
        <div class="content">
          <div class="content-1">
            <img src="../../assets/wenhua-11.jpg" />
            <div class="text">
              <b>◎金天使命：</b><br />
              扬中医养生之魂，振民族品牌之威，实现雪莲养护产业化，让更多的生命充满活力
            </div>
          </div>
          <div class="content-2">
            <div class="text">
              <b>◎金天理念：</b><br />
              开拓创新、诚信务实<br /><br />
              <b>◎金天精神：</b><br />
              信念成就未来<br /><br />
              <b>◎金天价值观：</b><br />
              让生命充满活力
            </div>
            <img src="../../assets1/wenhua-22.jpg" />
          </div>
          <div class="content-3">
            <img src="../../assets/wenhua-33.jpg" />
            <div class="text">
              <b>◎金天愿景：</b><br />
              成为国际一流绿色健康品牌，做百年长盛企业，让雪莲养护持续造福人类!
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="集团规划" class="guihua">
        <div class="wrapper">
          <div class="content">
            <p style="text-indent: 1.9rem">
              <b>
                金天国际第一个30年发展规划：实现生殖养护产业化，让生命充满活力
              </b>
            </p>
            <p style="text-indent: 1.9rem">
              <b>
                产品:
              </b>
            </p>
            <p style="text-indent: 1.9rem">
              金天国际创造生殖养护品---雪莲养护贴，历经 30 年完成 5 次产品更迭
            </p>
            <p style="text-indent: 1.9rem">
              1993 年开创 首创雪莲贴垫剂型--妇得乐雪莲药垫（治疗功能）
            </p>
            <p style="text-indent: 1.9rem">
              1996 年升级 二代雪莲贴垫剂型--复方雪莲药垫（预防保健功能）
            </p>
            <p style="text-indent: 1.9rem">
              2004 年迭代 三代雪莲贴垫剂型—雪莲妇贴（护理功能）
            </p>
            <p style="text-indent: 1.9rem">
              2006 年醇熟 四代雪莲贴垫剂型—雪莲生态保养贴（保养功能）
            </p>
            <p style="text-indent: 1.9rem">
              2022 年产业化 五代雪莲贴垫剂型—雪莲养护贴（行业标准，养护功能）
            </p>
            <p style="text-indent: 1.9rem">
              <b>
                技术:
              </b>
            </p>
            <p style="text-indent: 1.9rem">
              拥有天山雪莲组培技术、产品功效成分数字化组方技术、指纹图谱质控分析技术（三大核心技术）及 50 余项专利技术。
            </p>
            <p style="text-indent: 1.9rem">
              <b>
                模式:
              </b>
            </p>
            <p style="text-indent: 1.9rem">
              独创三维动态特许专营模式。
            </p>
            <p style="text-indent: 1.9rem">
              <b>
                产业：
              </b>
            </p>
            <p style="text-indent: 1.9rem">
              雪莲养护贴行业标准的颁布和实施，标志着生殖养护产业正式诞生，一种继刷牙、护肤之后， 服务于全人类生殖养护的第三种生活品质方式正式开启。
            </p>
            <p style="text-indent: 1.9rem">
              至此，金天国际第一个 30 年规划【产品、技术、模式、产业】完美收官。
            </p>
            <p style="text-indent: 1.9rem">
              <b>
                金天国际第二个30年发展规划：普及雪莲养护贴产业，让更多生命充满活力
              </b>
            </p>
            <p style="text-indent: 1.9rem">
              <b>
                产业拓展期：
              </b>
            </p>
            <p style="text-indent: 1.9rem">
              2023 年-2033 年完成全球市场的布局，2025 年前贴 R 用户数量达到 500 万以上，年市场销售额 500 亿以上；2027 年前贴 R用户数量达到 1000 万以上，年市场销售额 1000 亿以上；2033 年前贴 R 用户数量达到 1 亿以上，年市场销售额万亿以上；
            </p>
            <p style="text-indent: 1.9rem">
              <b>
                产业巩固期：
              </b>
            </p>
            <p style="text-indent: 1.9rem">
              2033 年-2043 年持续普及生殖养护之雪莲养护的生活品质方式，让全球至少 10 亿人养成如刷牙护肤一样的生殖养护习惯，全球实现十万亿级产业市场规模；
            </p>
            <p style="text-indent: 1.9rem">
              <b>
                产业提升期：
              </b>
            </p>
            <p style="text-indent: 1.9rem">
              2043 年-2053 年在持续科研的基础上，根据市场需求变化推出更多适应生殖养护的新技术、新标准、新方式，打造全球最有价值的企业，成就百年长盛的伟大企业。
            </p>
            <!-- <div class="content-tips">第一阶段：开创期 1993-2002：</div>
            <p>
              奠定发展基础，企业资产突破1亿元，1997年营业额实现2亿2千万，并保持稳步发展。
            </p>
            <div class="content-tips">第二阶段：巩固期 2003-2012：</div>
            <p>
              完善企业核心技术，建立适应国际运作的现代企业管理机制，及建立适应未来市场规模化发展的全新商业模式，经10年研发，现已拥有生殖养护、生物气波等生命养护技术及几十项专有专利技术，至2007年已按照产业化格局形成了自主知识产权和核心竞争力，并在新加坡组建生殖养护国际研发中心及金天国际集团总部，标志着企业拉开国际化运作的序幕。与此同时，全新的商业模式逐步成熟完善，企业巩固期完美落幕。
            </p>
            <div class="content-tips">第三阶段：发展期 2013-2022：</div>
            <p>
              自2013年起，金天国际进入冲刺产业化的全速发展期，决心用十年的时间，使雪莲养护贴系列产品粉丝级用户达到1000万人，成为世界知名企业和国际著名品牌，实现雪莲养护产业化，让更多的生命充满活力！
            </p>
            <p style="text-indent: 1.9rem">
              第二个30年发展规划:
            </p>
            <p style="font-weight:bold;">
              企业规划与国家第二个百年奋斗目标的进程同步，开启下一个30年的全新征程：成为一个利国利民、助力中华民族伟大复兴的民族企业，助力国家第二个百年奋斗目标的实现，让中医养生精髓持续造福人类。
            </p>
            <div class="content-tips">产业拓展期2023-2033年：</div>
            <p>
              完成全球市场的布局，忠实用户数量达到500万以上，年市场销售额500亿以上。2027年前完成全球忠实用户数量达到1000万以上，年市场销售额1000亿以上，2033年前完成全球忠实用户数量达到1亿以上，年市场销售额万亿以上；
            </p>
            <div class="content-tips">产业提升期2034-2043年：</div>
            <p>
              持续普及生殖养护之雪莲养护的健康生活方式，让全球至少10亿人养成如刷牙护肤一样生殖养护习惯，全球实现十万亿级产业市场规模；
             </p>
            <div class="content-tips">产业巩固期2044-2053年：</div>
            <p>
              在持续科研的基础上，根据市场需求变化推出更多适应生殖养护的新技术、新标准、新方式，打造全球最有价值的企业，成就百年长盛的伟大企业。
            </p> -->
          </div>
          <!-- <img class="img" src="../../assets/guihua-1.jpg" /> -->
        </div>
      </van-tab>
      <van-tab title="集团产业" class="chanye">
         <div class="wrapper">
          <p style="text-indent: 1.9rem">
            金天国际现已形成“生殖养护、美好商城、云健康、飞行营地”暨一核三星四大产业发展格局。
          </p>
          <p style="font-weight:bold;text-indent: 1.9rem">
           一核三星产业：
          </p>
          <p style="text-indent: 1.9rem"><b>生殖养护产业</b></p>
          <p style="text-indent: 1.9rem">
            2021年10月8日，由金天国际核心企业烟台金蕊女性用品有限公司、江苏金天雪莲生态保养有限公司及中国科学院青岛生物能源与过程研究所等发起并起草，经中国轻工业联合会提出并归口的《雪莲养护贴》行业标准(QB/T5630-2021)，由中华人民共和国工业和信息化部正式向全球首次发布并于2022年2月1日正式实施。
          </p>
          <p style="text-indent: 1.9rem">
            在《雪莲养护贴》标准“范围”内明确规定:雪莲养护贴是“以纯棉织物作为亲肤面层，将雪莲等多种草本植物制成的精华素置于中间功能层，再覆以透气膜经专用机械加工成型，用于人体生殖部位养护的一次性用品。”至此，雪莲养护贴是中国以行标的法定形式率先在全球发布的生殖养护技术和产品，由此开启了一个全新的生殖养护产业。
          </p>
          <p style="text-indent: 1.9rem">
            雪莲养护贴行业标准的颁布和实施标志着雪莲养护贴成为了满足人们生殖养护需求的新技术、新产品和新方式。一种继刷牙、护肤之后，服务于全人类生殖养护的第三大生活品质方式正式开启，一个全新的生殖养护产业正式诞生，今年流行贴R正在席卷全球！
          </p>
          <p style="text-indent: 1.9rem"><b>美好商城</b></p>
          <p style="text-indent: 1.9rem">
            金天合纵是在中国国际经济交流中心、中国贸促会、中国国际商会、中国流通协会、中国健康促进基金会等国内权威机构的支持和指导下，由金天国际发起并承建的全球首个新消费交互式企业联盟商城。
          </p>
          <!-- <p style="text-indent: 1.9rem"><b>金天合纵产业</b></p>

          <p style="text-indent: 1.9rem">
            金天合纵顺应“十四五”战略，响应构建国内经济大循环发展国策，以组建重大创新联合体发挥企业在经济创新中的主体作用，金天国际联合万千中小企业共同打造新消费商业生态联盟，以30年深厚底蕴及经验，为广大中小企业赋能。
          </p> -->
          <p style="text-indent: 1.9rem"><b>飞行营地产业</b></p>
          <p style="text-indent: 1.9rem">
            金天国际飞行营地是金天国际集团与蓝天航空集团通过优势互补、强强联合组建的一家以创新发展中国通用航空事业为己任的平台式通用航空经营实体，是经中国民用航空局批准成立的涵盖甲、乙、丙三类经营范围的通用航空公司。
           </p>
           <p style="text-indent: 1.9rem"><b>云健康产业</b></p>
          <p style="text-indent: 1.9rem">
            专注于5S全生命质量管理、细胞技术的研发与应用，与中国健康促进基金会、中国医学科学院&北京协和医学院等权威机构实现产学研共建，打造国内一流的生命质量管理产业基地。
          </p>
        </div>
      </van-tab>
      <van-tab title="集团产品" class="chanpin">
        <div class="wrapper" style="width: 350px;height:100%;margin:0 auto;">
          <!-- <van-empty description="正在加载数据" /> -->
          <!-- <img style="width: 100%;height: 100%;" class="img" src="../../assets/集团产品.jpg" /> -->
          <img style="width: 100%;height: 100%;" class="img" src="../../assets1/cpjs_01.jpg" />
          <img style="width: 100%;height: 100%;" class="img" src="../../assets1/cpjs_02.jpg" />
          <img style="width: 100%;height: 100%;" class="img" src="../../assets1/cpjs_03.jpg" />
          <img style="width: 100%;height: 100%;" class="img" src="../../assets1/cpjs_04.jpg" />
          <img style="width: 100%;height: 100%;" class="img" src="../../assets1/cpjs_05.jpg" />
          <img style="width: 100%;height: 100%;" class="img" src="../../assets1/cpjs_06.jpg" />
          <img style="width: 100%;height: 100%;" class="img" src="../../assets1/cpjs_07.jpg" />
          <img style="width: 100%;height: 100%;" class="img" src="../../assets1/cpjs_08.jpg" />
          <img style="width: 100%;height: 100%;" class="img" src="../../assets1/cpjs_09.jpg" />
          <img style="width: 100%;height: 100%;" class="img" src="../../assets1/cpjs_10.jpg" />
        </div>
      </van-tab>
      <van-tab title="发展历程" class="licheng">
        <div class="wrapper">
          <div class="item" v-for="(item, index) in progressData" :key="index">
            <div class="content-steps">
              <div class="circle"></div>
              <div class="vertical-line"></div>
            </div>
            <div class="content">
              <div class="title">{{ item.year }}年</div>
              <div class="text">
                <p v-for="(wrapper, i) in item.wrapper" :key="i">
                  ● {{ wrapper.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      
    </van-tabs>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import baseURL from "../../utils/config";
import { progressData } from "../../utils/progressData.js";
import { qiyeData, chanpinData } from "../../utils/rongyuData.js";
import axios from 'axios';
export default {
  data() {
    return {
      active: 1,
      // imgUrl: baseURL.imageUrl,
      imgUrl: 'https://www.jtgj.cc',
      newsData: [],
      progressData: [],
      qiyeData: [],
      chanpinData: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {
    this.getNews();
  },
  mounted() {
    this.active = Number(this.$route.params.tabIndex);
    this.getNews();
    this.progressData = progressData;
    this.qiyeData = qiyeData;
    this.chanpinData = chanpinData;
  },
  watch: {
    "$route.path": function () {
      this.active = Number(this.$route.params.tabIndex);
    },
  },
  methods: {
    getNews() {
      // this.$api.getNews().then((res) => {
      //   this.newsData = res;
      // });
      let a = window.location.href
      console.log(a)
      if(a == 'https://wap.jtgj.cc/#/into/1') {
      // if(a == 'http://192.168.3.85:8080/#/into/1') {
        axios.defaults.baseURL = 'https://www.jtgj.cc'
        axios.get('/jtywapi').then(response =>{
          this.newsData = response.data
        }).catch(error =>{})
      }else if(a == 'http://wap.jtgj.cc/#/into/1'){
        axios.defaults.baseURL = 'https://www.jtgj.cc'
        axios.get('/jtywapi').then(response =>{
          this.newsData = response.data
        }).catch(error =>{})
      }
      // this.$http.get('https:/www.jtgj.cc/jtywapi')
      //   .then(response => {
      //   this.newsData = response.data
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },
    onClick(name,title){
      this.$router.push({
        name: "into",
        params: { tabIndex: name },
      });
    },
    dateFormat(fmt, date) {
      date = new Date(Number(date));
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  .into-banner img {
    width: 100%;
    margin-top: 3.5rem;
  }
  .jianjie {
    .neir {
      margin: 0.5rem;
      border: 0;
      font-size: 0.8rem;
      line-height: 1.2rem;
      margin: 0 1rem;
      p {
        text-align: justify;
        font-size: 0.9rem;
        color: #6b6b6b;
      }
    }
  }
  .yaowen {
    .item {
      height: 12rem;
      margin: 0.5rem;
      padding: 0.5rem;
      border: solid #e2e2e2 0.1rem;
      .item-img img {
        width: 100%;
        height: 8rem;
      }
      .item-title {
        font-weight: bold;
        margin-top: 0.5rem;
        font-size: 0.9rem;
        color: #181818;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .item-detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0.5rem;
        color: #9d9d9d;
        margin-right: 0.2rem;
        font-size: 0.8rem;
      }
    }
  }
  .wenhua {
    .content {
      margin: 0.8rem;
      .content-1 {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        // img {
        //   width: 50%;
        //   flex: 1;
        //   height: 8rem;
        // }
        .text {
          flex: 1;
          margin-left: 1rem;
          margin-top: 0.2rem;
          color: #6b6b6b;
          line-height: 1.5rem;
          font-size: 0.9rem;
          b {
            color: #666666;
            font-size: 1.1rem;
          }
        }
      }
      .content-2 {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        // img {
        //   width: 45%;
        //   height: 12.9rem;
        //   flex: 1;
        //   margin-left: 2rem;
        // }
        .text {
          flex: 1;
          margin-top: 0.2rem;
          color: #6b6b6b;
          line-height: 1.6rem;
          font-size: 0.9rem;
          b {
            color: #666666;
            font-size: 1.1rem;
          }
        }
      }
      .content-3 {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        // img {
        //   flex: 1;
        //   width: 50%;
        //   height: 8rem;
        // }
        .text {
          flex: 1;
          margin-left: 1rem;
          margin-top: 0.5rem;
          color: #6b6b6b;
          line-height: 1.5rem;
          font-size: 0.9rem;
          b {
            color: #666666;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  .guihua {
    .wrapper {
      .content {
        padding: 0 0.5rem;
        color: #6b6b6b;
        line-height: 1.3rem;
        font-size: 0.9rem;
        .content-tips {
          width: 50%;
          height: 2rem;
          font-weight: bold;
          text-align: center;
          line-height: 1.8rem;
          margin-top: 1rem;
          color: #ffffff;
          background-image: url("../../assets/guihua-tips.png");
          -moz-background-size: 100% 100%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
      .img {
        width: 100%;
        margin-bottom: 1rem;
      }
      p {
        text-align: justify;
      }
    }
  }
  .chanye {
    .wrapper {
      padding: 0.5rem;
      color: #818181;
      font-size: 0.9rem;
    }
  }
  .chanpin {
    .wrapper {
      padding: 0.5rem;
      color: #818181;
      font-size: 0.9rem;
    }
  }
  .licheng {
    .wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      .item {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
        .content-steps {
          margin-left: 1rem;
          .circle {
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
            background-color: #ffffff;
            border: solid #df0272 0.2rem;
            margin-top: 0.2rem;
          }
          .vertical-line {
            width: 0.1rem;
            height: 100%;
            background-color: #e2e2e2;
            margin-left: 0.55rem;
          }
        }
        .content {
          margin-left: 1rem;
          .title {
            color: #000000;
            font-size: 1.6rem;
          }
          .text {
            color: #6b6b6b;
            font-size: 0.9rem;
            margin-right: 1rem;
          }
        }
      }
      p {
        text-align: justify;
      }
    }
  }
  .rongyu {
    .label {
      margin-top: 1rem;
      color: #de006d;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
    }
    .wrapper {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
      .item {
        flex: 0 0 50%;
        .item-content {
          display: flex;
          flex-direction: column;
          height: 10rem;
          margin: 0.4rem;
          padding: 0.1rem;
          justify-content: center;
          align-items: center;
          border: dashed#A1A1A1 0.1rem;
          border-radius: 0.5rem;
          img {
            height: 5.5rem;
            margin: 0.2rem;
          }
          .title {
            height: 1.5rem;
            margin-top: 0.4rem;
            color: #6c6c6c;
            font-size: 0.6rem;
            text-align: center;
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .content {
    .content-1 {
      img {
        width: 50%;
        flex: 1;
        height: 8rem;
      }
    }
    .content-2 {
      img {
        width: 45%;
        height: 12.9rem;
        flex: 1;
        margin-left: 2rem;
      }
    }
    .content-3 {
      img {
        flex: 1;
        width: 50%;
        height: 8rem;
      }
    }
  }
}
@media screen and (min-width:501px) and (max-width:900px) {
  .content {
    .content-1 {
      img {
        width: 50%;
        flex: 1;
        height: 12rem;
      }
    }
    .content-2 {
      img {
        width: 45%;
        height: 23.9rem;
        flex: 1;
        margin-left: 2rem;
      }
    }
    .content-3 {
      img {
        flex: 1;
        width: 50%;
        height: 12rem;
      }
    }
  }
}
@media screen and (min-width:901px) {
  .content {
    .content-1 {
      img {
        width: 50%;
        flex: 1;
        height: 16rem;
      }
    }
    .content-2 {
      img {
        width: 45%;
        height: 28.9rem;
        flex: 1;
        margin-left: 2rem;
      }
    }
    .content-3 {
      img {
        flex: 1;
        width: 50%;
        height: 16rem;
      }
    }
  }
}
</style>